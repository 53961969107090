<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="feedbackpage">
      <heade></heade>
      <div class="con">
          <div class="box">
              <p class="p1">导入《{{text_name}}》成功，导入成功数量为{{num1}}条，<span style="cursor:pointer; " @click="dianji_jinru_shibai">失败{{num2}}条。</span></p>
              <p v-if="shifou_zhanshi==1" style="font-size:0.18rem;color:red;">本产品仅识别10000条数据</p>
              <img src="../../assets/duigou.png" alt="">
              <p class="p2">成功导入《{{text_name}}》{{num1}}条</p>
              <div class="foot">
                  <p @click="dianji_fanhui">返回</p>
                  <!-- <p @click="dianji_zaici">再次导入</p> -->
              </div>
              <!-- <router-link to="#">查看失败表单</router-link> -->
          </div>
      </div>
      <foote></foote>
    </div>
</template>

<script>
import $ from 'jquery'
import heade from '../../components/Heade'
import foote from '../../components/Foote'
export default {
  name: 'feedbackpage',
  data () {
    return {
      text_name: '',
      xinxi: '',
      num1: '',
      num2: '',
      shifou_zhanshi: 0
    }
  },
  components: { heade, foote },
  created () {
    if (sessionStorage.getItem('pc_mlbb_shangchuan_chenggong') != null && sessionStorage.getItem('pc_mlbb_shangchuan_chenggong') != undefined && sessionStorage.getItem('pc_mlbb_shangchuan_chenggong') != '') {
      this.text_name = sessionStorage.getItem('pc_mlbb_shangchuan_chenggong')
    }
    if (sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju2') != null && sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju2') != undefined && sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju2') != '') {
      this.xinxi = sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju2')
    }
    if (sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju') != null && sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju') != undefined && sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju') != '') {
      const data = JSON.parse(sessionStorage.getItem('pc_mlbb_shangchuan_chenggong_shuju'))
      if (data == null) {
      } else {
        this.num1 = data.success_size
        this.num2 = data.fail_size
        this.shifou_zhanshi = data.sum_flag
      }
    }
  },
  methods: {
    dianji_fanhui () {
      this.$router.go(-2)
    },
    dianji_zaici () {
      this.$router.go(-1)
    },
    dianji_jinru_shibai () {
      // let date={
      //     name:'',
      //     cuowu:true
      // }
      // if(this.text_name=='部门'){
      //     date.name='部门'
      // }else if(this.text_name=='科目'){
      //     date.name='科目'
      // }else if(this.text_name=='产品'){
      //     date.name='产品'
      // }
      // sessionStorage.setItem("pc_mlbb_bumenrzhi", JSON.stringify(date));
      const date = {
        name: '',
        cuowu: true
      }
      if (this.xinxi == '部门') {
        date.name = '部门'
      } else if (this.xinxi == '科目') {
        date.name = '科目'
      } else if (this.xinxi == '产品') {
        date.name = '产品'
      } else if (this.xinxi == '客户') {
        date.name = '客户'
      } else if (this.xinxi == '报价单维护') {
        date.name = '报价单维护'
      } else if (this.xinxi == '供应商录入单') {
        date.name = '供应商录入单'
      }
      sessionStorage.setItem('pc_mlbb_bumenrzhi', JSON.stringify(date))
      this.$router.push('/calendara')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.feedbackpage {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        background:#E7E8EB;
        padding-top:2.2rem;
        padding-bottom: 0.56rem;
        margin: 0 auto;
        min-height: 5.5rem;
        .box{
            background: #fff;
            width:12rem;
            height:4.47rem;
            padding: 0.28rem;
            margin: 0 auto;
            .p1{
                height:0.49rem;
                padding: 0 0.12rem;
                background: #F4F8FF;
                line-height: 0.49rem;
                font-size: 0.18rem;
                color:#4C4A4D;
                span{
                    font-size: 0.18rem;
                    color:#F96C6C;
                }
            }
            img{
                height:0.69rem;
                width:0.69rem;
                display: block;
                margin: 0.23rem auto;
            }
            .p2{
                font-size: 0.24rem;
                color:#1A2533;
                text-align: center;
                margin-top:0.4rem;
            }
            .foot{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 1rem;
                p{
                    width:1.79rem;
                    height: 0.45rem;
                    margin: 0 0.2rem;
                    line-height: 0.45rem;
                    text-align: center;
                    font-size: 0.22rem;
                    border-radius: 0.06rem;
                    cursor:pointer; 
                    &:nth-child(1){
                        background: #F96C6C;
                        color:#fff;
                    }
                    &:nth-child(2){
                        background: #fff;
                        border:0.01rem solid #979797;
                        color: #888888;
                    }
                }
            }
            a{
                display: block;
                margin-top:0.5rem;
                text-align: center;
                color: #A5C6FD;
                font-size: 0.18rem;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
        background:#E7E8EB;
      }
    }
}
@media screen and (max-width: 12rem) {
    .feedbackpage {
        width: 12rem;
        background:#E7E8EB;
    }
}
</style>
